import { Filter } from "./Filter";
import styles from "./filters.module.scss";

export const Filters = ({ filters, onChange }) => {
    return (
        <ul className={styles.filters}>
            {filters.map((item, index) => (
                <Filter key={index} item={item} onChange={onChange} />
            ))}
        </ul>
    )
}

import { baseUrl } from "./apiConfig";
import { refreshAccessToken } from "./authApi";

export const updateCustomer = async (token, orderId, value) => {
    try {
        const response = await fetch( `${baseUrl}customers/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order_id: orderId,
                ...value,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return updateCustomer(res.access, orderId, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}
import React from "react";
import { SortItem } from "./SortItem";
import styles from "./fabricSort.module.scss";

export const FabricsSort = ({ sortOptions, selectedSortParam, setSortParam }) => {
    return (
        <ul className={styles.fabricSortList}>
            {sortOptions.length > 0 && sortOptions.map((item, idx) => (
                <SortItem
                    key={idx}
                    item={item}
                    isSelected={item.name === selectedSortParam}
                    onChange={setSortParam}
                />
            ))}
        </ul>
    )
}

import styles from './TotalPrice.module.scss';

const TotalPrice = ({ totalPrice, priceWithDiscount }) => {
    return (
        <div className={styles.totalPrice}>
            {totalPrice > priceWithDiscount && (
                <p className={`${styles.oldPriceValue} ${styles.price}`}>{totalPrice || 0} грн</p>
            )}
            <div className={styles.priceInfo}>
                <p className={styles.priceText}>Ціна замовлення:</p>
                <span className={`${styles.totalPriceValue} ${styles.price}`}>{priceWithDiscount || 0} грн</span>
            </div>
        </div>
    )
}

export default TotalPrice;
import React from "react";
import styles from '../../FabricSet.module.scss';

const FabricSetItemOptions = ({chosenOptions, setChosenOptions, item, componentIndex, onChangeOptions, setContainerPosition, isCustom}) => {
    let optionsInfo = item?.options || [];

    const handleOptionClick = (option) => {
        setContainerPosition(2);
        if (chosenOptions.some((el) => el.id === option.id)) {
            const filteredArr = [...chosenOptions].filter((el) => el.id !== option.id);
            setChosenOptions(filteredArr);
        } else {
            setChosenOptions([...chosenOptions, {name: option.name, id: option.id, price: option.price}]);
        }

        onChangeOptions(option.id, componentIndex, 'option', isCustom);
    };

    return (
        <div>
            {optionsInfo && optionsInfo.length > 0 && <span>Опції:</span>}
            <div className={styles.optionGroup}>
                {optionsInfo && optionsInfo.length > 0 && optionsInfo.map((el, index) => (
                    <button
                        key={index}
                        className={`${styles.option} ${chosenOptions.some((opt) => opt.id === el.id) ? styles.active : ""}`}
                        onClick={() => handleOptionClick(el)}
                    >
                        <span>{el.name}</span>
                    </button>
                ))}
            </div>
        </div>
    )
}

export default FabricSetItemOptions
import React, {useEffect, useState} from "react";
import styles from './Order.module.scss';
import paste from '../../../assets/icons/pasteIcon.svg';
import {useFormik} from 'formik';
import UrgentlyField from "./UrgentlyField";
import {useAppSelector} from "../../../hooks/redux";
import {getUser} from "../../../store/selectors";
import Parser from "../../Common/Parser/Parser";
import InputMask from "react-input-mask";
import { getAccessToken } from "../../../utils/authTokens";
import { updateCustomer } from "../../../api/customersApi"
import { updatePayment } from "../../../api/paymentApi";
import { updateOrder } from "../../../api/ordersApi";

const OrderCreate = ({order}) => {
    const manager = useAppSelector(getUser);
    const [isUrgently, setIsUrgently] = useState(false);
    const [saveData, setSaveData] = useState('');
    const isDisabled = order?.payment?.status === 'PAID' || order?.payment?.status === 'PAY_WAIT';
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (order?.payment) {
            if (isUrgently !== order.is_immediate) {
                setIsUrgently(order.is_immediate);
            }
        }
    }, [order])

    const formValues = {
        userUrl: order.customer && order.customer.url ? order.customer.url : '',
        nickName: order.customer && order.customer.username ? order.customer.username : '',
        first_name: order.customer && order.customer.first_name ? order.customer.first_name : '',
        last_name: order.customer && order.customer.last_name ? order.customer.last_name : '',
        middle_name: order.customer && order.customer.middle_name ? order.customer.middle_name : '',
        phone: order.customer && order.customer.phone ? order.customer.phone : '',
        comment: order.comment ? order.comment : '',
    }

    const {handleSubmit, handleChange, values, setValues, setFieldValue} = useFormik({
        initialValues: formValues,
    });

    const auditDates = (value, fieldName, type) => {
        const accessToken = getAccessToken();
        if (saveData !== value && type === 'customer') {
            const processedValue = fieldName === 'phone'
                ? value.replace(/[ ()_-]/g, '')
                : ['first_name', 'last_name', 'middle_name'].includes(fieldName)
                    ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
                    : value;
            updateCustomer(accessToken, order.id, {[fieldName]: processedValue}).then((res) => {
                if (res?.id) {
                    setErrors(prevErrors => {
                        const { [fieldName]: _, ...restErrors } = prevErrors;
                        return restErrors;
                    });
                } else {
                    setErrors(prevErrors => ({ ...prevErrors, [fieldName]: res[fieldName]?.[0] || 'Invalid' }));
                }
            })
        } else if (saveData !== value && type === 'payment') {
            updatePayment(accessToken, order.id, {[fieldName]: value}).catch(err => console.error(err));
        } else if (saveData !== value && type === 'order') {
            updateOrder(accessToken, order.id, manager.id, {[fieldName]: value}).catch((e) => console.log(e))
        }
    }

    const handleURLChange = (e) => {
        if (e.target.value) {
            const nickname = e.target.value.match(/(?:https?:\/\/)?(?:www.)?(?:instagram)(?:.com\/)?([@a-zA-Z0-9-_]+)/);
            if (Array.isArray(nickname) && nickname[1]) {
                setFieldValue("nickName", nickname[1]);
            } else {
                setFieldValue("nickName", "");
            }
        } else {
            setFieldValue("nickName", "");
        }

        handleChange(e);
    }

    const updateData = (value, fieldName = null) => {
        const accessToken = getAccessToken();
        if (fieldName) {
            updateCustomer(accessToken, order?.id, { [fieldName]: value }).catch((e) => console.log(e))
        } else {
            updateCustomer(accessToken, order?.id, value).catch((e) => console.log(e))
        }
    }

    const onSubmit = (value) => {
        auditDates(value, 'status', 'order')
    }

    const handleValidChange = (key, value) => {
        if (/[^a-zа-яґєії+\-' ]+/ig.test(value) && value !== '') {
            return;
        }
        setFieldValue(key, value);
    }

    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setFieldValue("userUrl", text);
            const nickname = text.match(/(?:https?:\/\/)?(?:www.)?(?:instagram)(?:.com\/)?([@a-zA-Z0-9-_]+)/);
            if (Array.isArray(nickname) && nickname[1]) {
                setFieldValue("nickName", nickname[1]);
                updateData({
                    url: text,
                    username: nickname[1]
                })
            } else {
                setFieldValue("nickName", "");
                updateData({
                    url: text,
                    username: ''
                })
            }
        } catch (err) {
            console.error('Failed to read clipboard: ', err);
        }
    }

    return (
        <div className={styles.order}>
            <form onSubmit={handleSubmit}>
                <h2 className={styles.order__title}>Замовлення</h2>
                <div className={styles.order__content}>
                    <p className={styles.order__header}>
                        <span>{order.id}</span>
                        {order && order.created && (
                            <span>{`${order.created.substring(8, 10)}.${order.created.substring(5, 7)}.${order.created.substring(0, 4)}`}</span>
                        )}
                    </p>
                    <div className={styles.form}>
                        <div className={styles.form__userUrl}>
                            <input
                                placeholder={'User URL'}
                                className={`baseInput ${isDisabled ? styles.disabled : ''}`}
                                id="userUrl"
                                name="userUrl"
                                type="text"
                                value={values.userUrl}
                                onChange={handleURLChange}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => {
                                    const accessToken = getAccessToken();
                                    updateCustomer(accessToken, order.id, {
                                        url: e.target.value,
                                        username: values.nickName
                                    }).then((res) => console.log(res))
                                }}
                            />
                            <button
                                className={styles.pasteBtn}
                                type='button'
                                onClick={handlePaste}
                                disabled={isDisabled}
                            >
                                <img src={paste} alt=""/>
                            </button>
                        </div>
                        <div>
                            <input
                                placeholder={'Нік'}
                                className={`baseInput ${isDisabled ? styles.disabled : ''}`}
                                aria-label={'username'}
                                id="nickName"
                                name="nickName"
                                type="text"
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'username', 'customer')}
                                value={values.nickName}
                            />
                        </div>
                        <div className={styles.form__name}>
                            <div className="baseInputContainer">
                            <span className="coloredLabel colored--first_name"></span>
                                <input
                                    placeholder={'Ім`я'}
                                    className={`baseInput ${isDisabled ? styles.disabled : errors.first_name ? 'baseInputError' : ''}`}
                                    aria-label={'first_name'}
                                    id={'first_name'}
                                    name="first_name"
                                    type="text"
                                    onChange={(e) => handleValidChange(e.target.name, e.target.value)}
                                    onFocus={(e) => setSaveData(e.target.value)}
                                    onBlur={(e) => auditDates(e.target.value, 'first_name', 'customer')}
                                    value={values.first_name}
                                />
                            </div>
                            <div className="baseInputContainer">
                            <span className="coloredLabel colored--last_name"></span>
                                <input
                                    placeholder={'Прізвище'}
                                    className={`baseInput ${isDisabled ? styles.disabled : errors.last_name ? 'baseInputError' : ''}`}
                                    aria-label={'last_name'}
                                    id="last_name"
                                    name="last_name"
                                    type="text"
                                    onChange={(e) => handleValidChange(e.target.name, e.target.value)}
                                    onFocus={(e) => setSaveData(e.target.value)}
                                    onBlur={(e) => auditDates(e.target.value, 'last_name', 'customer')}
                                    value={values.last_name}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="baseInputContainer">
                            <span className="coloredLabel colored--middle_name"></span>
                                <input
                                    placeholder={'По батькові'}
                                    className={`baseInput ${isDisabled ? styles.disabled : errors.middle_name ? 'baseInputError' : ''}`}
                                    aria-label={'middle_name'}
                                    id="middle_name"
                                    name="middle_name"
                                    type="text"
                                    onChange={(e) => handleValidChange(e.target.name, e.target.value)}
                                    onFocus={(e) => setSaveData(e.target.value)}
                                    onBlur={(e) => auditDates(e.target.value, 'middle_name', 'customer')}
                                    value={values.middle_name}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="baseInputContainer">
                            <span className="coloredLabel colored--phone"></span>
                                <InputMask
                                    mask="+38 (099) 999-99-99"
                                    value={values.phone}
                                    onChange={(e) => handleChange(e)}
                                    onFocus={(e) => setSaveData(e.target.value)}
                                    onBlur={(e) => auditDates(e.target.value, 'phone', 'customer')}
                                >
                                    {(inputProps) => (
                                        <input
                                            {...inputProps}
                                            placeholder={"Телефон замовника"}
                                            className={`baseInput ${isDisabled ? styles.disabled : errors.phone ? 'baseInputError' : ''}`}
                                            aria-label={"phone"}
                                            id="phone"
                                            name="phone"
                                        />
                                    )}
                                </InputMask>
                            </div>
                        </div>
                        <UrgentlyField setIsUrgently={setIsUrgently} isUrgently={isUrgently} auditDates={auditDates} isDisabled={isDisabled}/>
                        <div>
                            <textarea
                                placeholder={'Коментар до замовлення'}
                                className={'baseInput'}
                                aria-label={'comment'}
                                id="comment"
                                name="comment"
                                value={values.comment}
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'comment', 'order')}
                            />
                        </div>
                    </div>
                    <div className={styles.action}>
                        <button type={'submit'} className={'globalBtn'} onClick={() => onSubmit('PROD')}
                                disabled={values.first_name.length < 3 || values.last_name.length < 3 || values.phone.length < 9 || order?.payment?.type === '' || (order?.payment?.type === 'POSTPAID' && !values.post_paid_amount)}>
                            <span>На виробництво</span>
                        </button>
                    </div>
                </div>
            </form>
            <Parser
                values={values}
                setValues={(newValues) => setValues(prevValues => ({ ...prevValues, ...newValues }))}
                updateData={updateData}
            />
        </div>
    )
}

export default OrderCreate;
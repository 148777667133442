import React, {useState} from "react";
import styles from './StorageItem.module.scss';
import IconPlaceholder from "../../../assets/img/storage_item_icon_placeholder.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";
import copyIcon from "../../../assets/icons/copy.svg";
import plus from "../../../assets/icons/plus.svg";
import closeIcon from "../../../assets/icons/closeCross.svg";
import PopupCheckboxes from "../PopupCheckboxes/PopupCheckboxes";
import {getBaseUrl} from "../../../store/selectors";
import Preloader from "../Preloader/Preloader";
import {setIsActivePopup} from "../../../store/main-slice";
import { useAppDispatch } from "../../../hooks/redux";
import { getAccessToken } from "../../../utils/authTokens";
import { setAddProduct } from "../../../api/productsApi";

const StorageItem = ({orderId, id, name, images, sizes, copy, setOrder = null, setPopup}) => {
    let basicCount = 4;
    const baseUrl = getBaseUrl;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [activeSizeIndex, setActiveSizeIndex] = useState(0);
    const [activeColorIndex, setActiveColorIndex] = useState(0);
    const initialSizes = sizes?.length > 0
        ? sizes.map(size => ({
            title: `${size.width}x${size.length}`,
            value: size.size_id
        }))
        : [];
    const initialColors = sizes[0]?.colors?.length > 0
        ? sizes[0].colors.map(color => ({ title: color.name, value: color.price_id }))
        : [];
    const [additionalSize, setAdditionalSize] = useState(initialSizes[0]?.value || '');
    const [additionalColor, setAdditionalColor] = useState(initialColors[0]?.value || '');
    const [isShowSizePopup, setIsShowSizePopup] = useState(false);
    const [isShowColorPopup, setIsShowColorPopup] = useState(false);
    const [sortedSizes, setSortedSizes] = useState(initialSizes);
    const [sortedColors, setSortedColors] = useState(initialColors);
    const [pending, setPending] = useState(false);
    const quantity = sizes[activeSizeIndex]?.colors?.[activeColorIndex]?.quantity || 0;

    const handleSizeClick = (sizeIndex, value) => {
        if (sizeIndex !== activeSizeIndex) {
            setActiveSizeIndex(sizeIndex);
            setAdditionalSize(value);
            const newSortedColors = sizes[sizeIndex]?.colors?.length > 0
                ? sizes[sizeIndex].colors.map(color => ({ title: color.name, value: color.price_id }))
                : [];
            setSortedColors(newSortedColors);
            setAdditionalColor(newSortedColors[0]?.value || '');
            setActiveColorIndex(0);
        }
    };

    const handleAdditionalSize = (value) => {
        let newSize = value.value;
        setAdditionalSize(newSize);
        const index = initialSizes.findIndex(size => size.value === newSize);
        if (index !== -1) {
            if (!initialSizes.slice(0, basicCount).some(size => size.value === newSize)) {
                let changedSizes = [...initialSizes];
                changedSizes.splice(index,1);
                changedSizes.unshift(value);
                setSortedSizes(changedSizes);
                setActiveSizeIndex(0);
            } else {
                setSortedSizes(initialSizes);
                setActiveSizeIndex(index);
            }
            const newColors = sizes[index]?.colors?.length > 0
                ? sizes[index].colors.map(color => ({ title: color.name, value: color.price_id }))
                : [];
            setSortedColors(newColors)
            setAdditionalColor(newColors[0]?.value || '');
            setActiveColorIndex(0);
        }
    }

    const handleColorClick = (colorIndex, value) => {
        if (colorIndex !== activeColorIndex) {
            setActiveColorIndex(colorIndex);
            setAdditionalColor(value);
        }
    };

    const handleAdditionalColor = (value) => {
        let newColor = value.value;
        setAdditionalColor(newColor);
        const index = initialColors.findIndex(color => color.value === newColor);
        if (index !== -1) {
            if (!initialColors.slice(0, basicCount).some(color => color.value === newColor)) {
                let changedColors = [...initialColors];
                changedColors.splice(index,1);
                changedColors.unshift(value);
                setSortedColors(changedColors);
                setActiveColorIndex(0);
            } else {
                setSortedColors(initialColors);
                setActiveColorIndex(index);
            }
        }
    }

    const openSizePopup = () => {
        setIsShowSizePopup(true);
    }

    const closeSizePopup = () => {
        setIsShowSizePopup(false);
    }

    const openColorPopup = () => {
        setIsShowColorPopup(true);
    }

    const closeColorPopup = () => {
        setIsShowColorPopup(false);
    }

    const onAddProduct = () => {
        setIsLoading(true);
        const accessToken = getAccessToken();
        setPending(true);
        const warehouseItem = { template: id, price: additionalColor };
        setAddProduct(accessToken, orderId, warehouseItem).then((response) => {
            if (response?.id) {
                setOrder(prevProducts => [...prevProducts, response]);
                setPopup(false);
                dispatch(setIsActivePopup(false));
                setIsLoading(false);
            }
            setIsLoading(false);
            setPending(false);
        })
    }

    return (
        <div className={styles.storageItem}>
            <div className={styles.infoWrap}>
                <div className={styles.titleWrap}>
                    {images?.length ? <img src={images[0]} className={styles.itemImg} alt=""/> :
                        <span className={styles.iconWrap}><img src={IconPlaceholder} alt=""/></span>}
                    <div>
                        <h3>{name}</h3>
                        <p className={quantity < 10 ? styles.balanceWarning : ''}>Залишок {quantity}</p>
                    </div>
                </div>
                {copy? <CopyToClipboard text={`${baseUrl}/product/?storageId=${id}`} className="click-effect">
                    <button tabIndex={0}>
                        <img src={copyIcon} alt=""/>
                    </button>
                </CopyToClipboard> :
                <div className={styles.addWrap}>
                    <button onClick={() => onAddProduct()} disabled={pending}>
                        <img src={plus} alt=""/>
                    </button>
                    <span>Додати</span>
                </div>}
            </div>
            {sortedSizes?.length > 0 && (
                <div className={styles.optionsWrap}>
                    <div>
                        {sortedSizes.slice(0, basicCount).map((el, index) => (
                            <span
                                key={index}
                                className={`${activeSizeIndex === index ? styles.active : ""}`}
                                onClick={() => handleSizeClick(index, el.value)}
                            >
                                <p>{el.title}</p>
                            </span>
                        ))}
                        {initialSizes.length > 4 && (
                            <span className={styles.additional} onClick={() => openSizePopup()}>
                                <p>+{initialSizes.length - basicCount}</p>
                            </span>
                        )}
                    </div>
                    {sortedColors?.length > 0 && (
                        <div className={styles.colorsRow}>
                            {sortedColors.slice(0, basicCount).map((el, index) => (
                                <span
                                    key={index}
                                    className={`${activeColorIndex === index ? styles.active : ""}`}
                                    onClick={() => handleColorClick(index, el.value)}
                                    style={{
                                        backgroundColor: el.title,
                                        borderColor: activeColorIndex === index ? '' : el.title
                                    }}
                                >
                                </span>
                            ))}
                            {sortedColors.length > 4 && (
                                <span className={styles.additional} onClick={() => openColorPopup()}>
                                    <p>+{sortedColors.length - basicCount}</p>
                                </span>
                            )}
                        </div>
                    )}
                </div>
            )}
            {(isShowSizePopup || isShowColorPopup) && (
                <span className={styles.shadowActive}/>
            )}
            {isShowSizePopup && (
                <div className={isShowSizePopup ? styles.globalPopupActive : styles.globalPopup}>
                    <div className={styles.header}>
                        <p className={styles.header__title}>Вказати розмір:</p>
                        <div className={styles.header__close} onClick={() => closeSizePopup()}>
                            <span>Закрити</span>
                            <button>
                                <img src={closeIcon} alt=""/>
                            </button>
                        </div>
                    </div>
                    <div className={styles.globalPopup__content}>
                        <PopupCheckboxes values={additionalSize} setValues={handleAdditionalSize} chooseList={initialSizes} isRadio/>
                    </div>
                </div>
            )}
            {isShowColorPopup && (
                <div className={isShowColorPopup ? styles.globalPopupActive : styles.globalPopup}>
                    <div className={styles.header}>
                        <p className={styles.header__title}>Вказати колір:</p>
                        <div className={styles.header__close} onClick={() => closeColorPopup()}>
                            <span>Закрити</span>
                            <button>
                                <img src={closeIcon} alt=""/>
                            </button>
                        </div>
                    </div>
                    <div className={styles.globalPopup__content}>
                        <PopupCheckboxes values={additionalColor} setValues={handleAdditionalColor} chooseList={sortedColors} isRadio/>
                    </div>
                </div>
            )}
            {isLoading && (
                <div className={styles.preloader}>
                    <Preloader />
                </div>
            )}
        </div>
    )
}

export default StorageItem;
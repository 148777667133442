import { useState } from "react";
import styles from "./filters.module.scss";

export const Filter = ({ item, onChange }) => {
    const [isActive, setIsActive] = useState(false);

    const toggleFilter = (id) => {
        setIsActive(!isActive);
        onChange(id);
    }

    return (
        <li className={styles.filter}>
            <label className={styles.filter__label}>
                <input type="checkbox" onChange={() => toggleFilter(item.id)} checked={isActive} />
                <span>{item.name}</span>
            </label>
        </li>
    )
}

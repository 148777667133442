import React from "react";
import styles from './PopupCheckboxes.module.scss';
import Preloader from "../Preloader/Preloader";

const PopupCheckboxes = ({values, setValues, chooseList, isRadio = false, isLoading = false}) => {
    const handleChange = (value) => {
        if (values.length) {
            const isItemValue = values.find((item) => item.value === value.value);
            if (isItemValue) {
                const newArray = values.filter((item) => item.value !== value.value);
                setValues([...newArray]);
            } else {
                setValues([...values, value])
            }
        } else {
            const newArray = [value];
            setValues(newArray)
        }
    }

    const setItem = (value) => {
        setValues(value);
    }

    return (
        <div className={styles.popupCheckboxes}>
            {chooseList && (
                chooseList.map((item, index) => (
                    <div key={index} className={`${styles.customCheckbox} ${item?.disabled || isLoading ? styles.disabled : ''}`}>
                        <input name={isRadio ? 'radioField' : null} type={isRadio ? 'radio' : 'checkbox'}
                               onChange={() => isRadio ? setItem(item) : handleChange(item)}
                               disabled={!!item?.disabled}
                               checked={isRadio ? values === item.value || values === item.title : values?.some((element) => element.value === item.value)} />
                        <span/>
                        <p>{item.title}</p>
                    </div>
                ))
            )}
            {isLoading && <Preloader />}
        </div>
    )
}

export default PopupCheckboxes;
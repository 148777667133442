import React, {useEffect, useState} from "react";
import PopupCheckboxes from "../../../../Common/PopupCheckboxes/PopupCheckboxes";

const OptionPopup = ({item, chosenOptions, setChosenOptions, onChangeOptions, componentIndex, isCustom}) => {

    let optionsInfo = item?.options || [];
    const [initialOptions, setInitialOptions] = useState([]);
    const [items, setItems] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        let formattedOptions = [];
        let formattedChosenOptions = [];
        if (optionsInfo) {
            if (Array.isArray(optionsInfo)) {
                formattedOptions = optionsInfo.map((el) => {
                    return {value: `${el.name}`, title: `${el.name}`, id: Number(el.id), price: el.price}
                });
            }
            if (Array.isArray(chosenOptions)) {
                formattedChosenOptions = chosenOptions.map((el) => {
                    return {value: `${el.name}`, title: `${el.name}`, id: Number(el.id), price: el.price}
                });
            }
            setInitialOptions(formattedOptions);
            setItems(formattedChosenOptions);
        }

    }, [item]);


    useEffect(() => {
        if (!firstLoad) {
            const formattedItems = items.map((el) => ({
                id: Number(el.id),
                name: el.value,
                price: el.price
            }));
            setChosenOptions(formattedItems);
            const changedOptions = items.map((el) => el.id);
            onChangeOptions(changedOptions, componentIndex, 'option', isCustom);
        }

        setFirstLoad(false);

    }, [items])

    return (
        <PopupCheckboxes values={items} setValues={setItems} chooseList={initialOptions}/>
    )
}

export default OptionPopup
import { baseUrl } from "./apiConfig";
import { getRefreshToken, removeAuthTokens, setNewAccessToken, setTokens } from "../utils/authTokens";

export const refreshAccessToken = async () => {
    try {
        const refreshToken = getRefreshToken();
        if (refreshToken) {
            const response = await fetch(`${baseUrl}users/token/refresh/`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refresh: refreshToken,
                })
            });
            const newAccessToken = response.json();

            newAccessToken.then((res) => {
                if (res?.access) {
                    setNewAccessToken(res.access);
                } else {
                    removeAuthTokens();
                    window.location.reload();
                }
            })
            return newAccessToken;
        }
    } catch (error) {
        if (error) {
            removeAuthTokens();
        }
    }
}

export const login = async (name, password) => {
    try {
        const response = await fetch(`${baseUrl}users/token/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: name,
                password: password,
            })
        });
        const result = response.json();
        result.then((res) => {
            if (res && res.access && res.refresh) {
                setTokens(res.access, res.refresh);
            } else {
                return result;
            }
        })
        return result;
    } catch (error) {
        console.log(error);
    }
}

export const getUserInfo = async (token) => {
    try {
        const response = await fetch(`${baseUrl}users/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res?.access) {
                    return getUserInfo(res.access).then((result) => {
                        return result;
                    });
                } else {
                    removeAuthTokens();
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}
import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import styles from './OrderStatus.module.scss';
import OrderInfoBlock from "../../Common/OrderInfoBlock/OrderInfoBlock";
import ProductItem from "../../Common/ProductItem/ProductItem";
import OrderActions from "./OrderActions";
import {useAppDispatch} from "../../../hooks/redux";
import Preloader from "../../Common/Preloader/Preloader";
import NotFound from "../../Common/NotFound/NotFound";
import { setGeneralOrderId, setIsNotFound } from "../../../store/main-slice";
import { getAccessToken } from "../../../utils/authTokens";
import { getDeliverNP } from "../../../api/novaPoshtaApi";
import { getCartOrder, removeProduct } from "../../../api/productsApi";
import { getOrderInfo } from "../../../api/ordersApi";
import { createPaymentBill, deletePaymentBill, getPaymentBills, getPayments, updatePayment, updatePaymentBill } from "../../../api/paymentApi";
import { Payment } from "./Payment/Payment";
import { Bill } from "./Bill/Bill";
import { getPriceWithDiscount } from "../../../utils/getPriceWithDiscount";
import { approveReceipt } from "../../../api/receiptApi";
import { getOrderMessageText } from "../../../utils/getOrderMessageText";

const OrderStatus = () => {
    const {id: orderId} = useParams();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const [bills, setBills] = useState([]);
    const [availableSum, setAvailableSum] = useState(0);
    const [warehouseItems, setWarehouseItems] = useState([]);
    const [deliveryInfo, setDeliveryInfo] = useState(null);
    const requiredPaymentFields = {
        first_name: order?.customer?.first_name,
        last_name: order?.customer?.last_name,
        phone: order?.customer?.phone,
        productsAmount: order?.kit_count + order?.warehouse_item_count || 0
    };
    const isDisabled = order?.payment?.status === 'PAID' || order?.payment?.status === 'NOT_PAID' || order?.payment?.status === 'PAY_WAIT' || !order?.payment?.final_amount;

    const getOrderStatusName = (orderStatus) => {
        switch (orderStatus) {
            case 'PAID': return 'Оплачено';
            case 'IN_PROG': return 'В обробці';
            case 'PAY_WAIT': return 'Очікує оплати'
            case 'NOT_PAID': return 'Не оплачено';
            default:
                console.log(orderStatus)
                break;
        }
        return orderStatus
    }

    const sendPayment = (payment) => {
        const accessToken = getAccessToken();
        updatePayment(accessToken, order.id, payment).then(res => {
            if (res?.id) {
                setOrder(prevOrder => ({ ...prevOrder, payment: res }));
            }
        })
    }

    const handleSaveFinalPrice = (finalPrice, discountType, discountValue) => {
        const roundedFinalPrice = Math.ceil(finalPrice);
        const payment = {
            final_amount: roundedFinalPrice,
            discount_type: discountType,
            discount_amount: discountValue,
        }
        const toPaySum = roundedFinalPrice - order.payment.already_paid_amount;
        const payWaitSum = bills.reduce((acc, el) => acc + (el.status !== 'PAID' ? el.prepayment_amount : 0), 0);
        setAvailableSum(toPaySum - payWaitSum);
        sendPayment(payment);
    }

    useEffect(() => {
        let accessToken = getAccessToken();
        getOrderInfo(accessToken, orderId).then((response) => {
            if (response?.id) {
                const generalOrderId = response.general_order?.id || orderId;
                if (response.general_order) {
                    dispatch(setGeneralOrderId(response.general_order.id));
                } else {
                    dispatch(setGeneralOrderId(null));
                }
                setOrder(response);
                accessToken = getAccessToken();
                getCartOrder(accessToken, orderId).then((response) => {
                    if (response && response.length > 0) {
                        setWarehouseItems(response);
                    }
                }).finally(() => {
                    setIsLoading(false);
                })
                getDeliverNP(accessToken, generalOrderId).then((result) => {
                    if (result && result.length > 0) {
                        setDeliveryInfo(result[0])
                    }
                })
                if (response?.payment?.id) {
                    getPaymentBills(accessToken, response.payment.id).then((result) => {
                        if (result?.length > 0) {
                            setBills(result);
                        }
                        const toPaySum = response.payment.final_amount - response.payment.already_paid_amount;
                        const payWaitSum = result.reduce((acc, el) => acc + (el.status !== 'PAID' ? el.prepayment_amount : 0), 0);
                        setAvailableSum(toPaySum - payWaitSum);
                    }).catch(err => console.log(err))
                }
            } else {
                setIsLoading(false);
                dispatch(setIsNotFound(true));
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }, [])

    useEffect(() => {
        if (order?.id && warehouseItems.length > 0) {
            const result = warehouseItems.reduce((sum, {warehouse_item, kit}) => sum + (warehouse_item ? warehouse_item.price : kit.price_retail), 0);
            const { discount_type, discount_amount } = order.payment;
            const priceWithDiscount = getPriceWithDiscount(result, discount_type, discount_amount);
            if (result !== order.payment.amount || priceWithDiscount !== order.payment.final_amount) {
                sendPayment({ amount: result, final_amount: priceWithDiscount });
            }
        }
    }, [order?.id, warehouseItems]);

    const handleDeleteItem = (id) => {
        const accessToken = getAccessToken();
        removeProduct(accessToken, id).then(result => {
            if (result === 204) {
                const newItems = warehouseItems.filter(item => item.id !== id);
                setWarehouseItems(newItems);
                if (newItems.length === 0) {
                    sendPayment({ final_amount: null, amount: null, status: 'NONE' });
                }
            }
        })
    }

    const onCopyAll = async () => {
        const resultText = getOrderMessageText(orderId, order, deliveryInfo, warehouseItems);
        try {
            await navigator.clipboard.writeText(resultText);
        } catch (error) {
            console.log(error);
        }
    }

    const handleAddNewBill = () => {
        const accessToken = getAccessToken();
        const body = {
            payment: order.payment.id,
            method: 'PREPAYMENT'
        }
        createPaymentBill(accessToken, body).then(resp => {
            setBills(prevBills => [...prevBills, resp]);
        }).catch(err => {
            console.log(err);
        })
    }

    const handleBillUpdate = (id, key, value) => {
        const accessToken = getAccessToken();
        const payload = {
            [key]: value,
            ...(key === 'method' && value === 'FULL' && { prepayment_amount: order.payment.final_amount }),
            payment: order.payment.id
        };
        updatePaymentBill(accessToken, id, payload).then(resp => {
            if (resp?.id) {
                const newBills = bills.map(bill => {
                    if (bill.id === resp.id) {
                        return resp;
                    }
                    return bill;
                });
                const toPaySum = order.payment.final_amount - order.payment.already_paid_amount;
                const payWaitSum = newBills.reduce((acc, el) => acc + (el.status !== 'PAID' ? el.prepayment_amount : 0), 0);
                setAvailableSum(toPaySum - payWaitSum);
                setBills(newBills);
            }
        }).catch(err => console.log(err)).finally(() => setIsLoading(false));
    }

    const handleBillDelete = (id) => {
        const accessToken = getAccessToken();
        deletePaymentBill(accessToken, id).then(status => {
            if (status === 204) {
                const newBills = bills.filter(bill => bill.id !== id);
                const toPaySum = order.payment.final_amount - order.payment.already_paid_amount;
                const payWaitSum = newBills.reduce((acc, el) => acc + (el.status !== 'PAID' ? el.prepayment_amount : 0), 0);
                setAvailableSum(toPaySum - payWaitSum);
                setBills(newBills);
            }
        })
    }

    const handleBillSubmitPayment = (id, approved) => {
        const accessToken = getAccessToken();
        const formData = new FormData();
        formData.append("payment", id);
        formData.append("is_approved", approved);

        approveReceipt(accessToken, formData).then(resp => {
            if (resp?.id) {
                const newBills = bills.map(bill => {
                    if (bill.id === resp.id) {
                        return resp;
                    }
                    return bill;
                });
                const toPaySum = order.payment.final_amount - order.payment.already_paid_amount;
                const payWaitSum = newBills.reduce((acc, el) => acc + (el.status !== 'PAID' ? el.prepayment_amount : 0), 0);
                setAvailableSum(toPaySum - payWaitSum);
                setBills(newBills);
                getPayments(accessToken, order.id).then((result) => {
                    if (result?.length > 0) {
                        setOrder(prevOrder => ({ ...prevOrder, payment: result[0] }));
                    }
                }).catch(err => console.log(err))
            }
        }).catch(err => console.log(err))
    }

    if (isLoading) {
        return (
            <Preloader />
        )
    } else if (!order) {
        return (
            <NotFound message={`Замовлення №${orderId} не знайдено`} />
        )
    }

    return (
        <div className={styles.orderStatus} style={{ backgroundColor: order?.is_general ? '#D9D1E0' : '#f7eadf' }}>
            {order && order.id && (
                <h2>{order?.is_general ? 'Замовлення' : 'Підзамовлення'} № {order.id}</h2>
            )}
            <OrderInfoBlock url={order?.customer?.url} departmentNumber={deliveryInfo?.receiver?.department_number} ttn={deliveryInfo?.delivery?.ttn}/>
            {order?.payment?.status && order?.payment?.status !== 'NONE' && (
                <div className={styles.statusBar}>
                    <span>{getOrderStatusName(order.payment.status)}</span>
                </div>
            )}
            {warehouseItems && warehouseItems.length > 0 && warehouseItems.map((el, index) => (
                <ProductItem
                    item={el}
                    key={index}
                    isDisabled={isDisabled}
                    onDelete={handleDeleteItem}
                />
            ))}
            <OrderActions orderUrl={order?.url} isDisabled={isDisabled} onCopyAll={onCopyAll} />
            {order?.payment && (
                <>
                    <h3>Оплата</h3>
                    <Payment
                        payment={order.payment}
                        handleSaveFinalPrice={handleSaveFinalPrice}
                        handleStatusChange={(status) => sendPayment({ status: status })}
                        isPaymentAllowed={Object.values(requiredPaymentFields).every((value) => !!value)}
                    />
                    {bills.length > 0 ? (
                        <>
                            <h3>Список рахунків</h3>
                            <div className={styles.bills}>
                                {bills.map((el, index) => (
                                    <Bill
                                        key={index}
                                        bill={el}
                                        number={index + 1}
                                        updateBill={(key, value) => handleBillUpdate(el.id, key, value)}
                                        onSubmitPayment={handleBillSubmitPayment}
                                        onDelete={handleBillDelete}
                                        isDisabled={isDisabled}
                                        isFullDisabled={bills.length > 1}
                                        availableSum={availableSum}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        <h3>Рахунки відсутні</h3>
                    )}
                    {order?.payment?.id && (
                        <button
                            className={styles.newBillBtn}
                            onClick={handleAddNewBill}
                            disabled={isDisabled || bills[0]?.method === 'FULL' || availableSum <= 0}
                        >
                            <span>Новий рахунок</span>
                        </button>
                    )}
                </>
            )}
        </div>
    )
}

export default OrderStatus;
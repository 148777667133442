import { getFormattedDate } from "../../../../utils/getFormattedDate";
import styles from "./Order.module.scss";
import removeIcon from "../../../../assets/icons/removeIcon.svg";
import editIcon from "../../../../assets/icons/pencil.svg";
import { Link } from "react-router-dom";

export const Suborder = ({ item, onDelete }) => {
    return (
        <div className={styles.suborder} onClick={(e) => e.stopPropagation()}>
            <div className={styles.suborder__header}>
                <span>#{item.id}</span>
                <span>{getFormattedDate(item.created)}</span>
            </div>
            <div className={styles.suborder__info}>
                <div>
                    <p>{item?.payment?.final_amount || 0} грн</p>
                </div>
                <div>
                    <span className={styles.text}>Всього товарів: {item.warehouse_item_count + item.kit_count}</span>
                </div>
            </div>
            <hr />
            <div className={styles.actions}>
                <button className={styles.btn} onClick={() => onDelete(item.id)}>
                    <img src={removeIcon} width={12} alt="Remove" />
                    <span>Видалити</span>
                </button>
                <a href={`/products/${item.id}`} className={styles.btn} alt="Edit">
                    <img src={editIcon} width={12} alt="Edit" />
                    <span>Змінити</span>
                </a>
            </div>
        </div>
    )
}

import React, {useEffect, useState} from "react";
import styles from './Calculator.module.scss';
import FabricType from "./FabricType/FabricType";
import FabricSize from "./FabricSize/FabricSize";
import FabricOptions from "./FabricOptions/FabricOptions";
import FabricSet from "./FabricSet/FabricSet";
import CalcButton from "./CalcButton/CalcButton";
import FabricPrice from "./FabricPrice/FabricPrice";
import { getAccessToken } from "../../../utils/authTokens";
import { getFabricTemplate, getMonoFabricTypeTemplates, setKit, setSplitKit, setUpdateKit } from "../../../api/calculatorApi";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsActivePopup} from "../../../store/main-slice";
import GlobalPopup from "../../Common/GlobalPopup/GlobalPopup";
import FabricSizeSecond from "./FabricSize/FabricSizeSecond";
import AddCommentButton from "./CommentButton/AddCommentButton";
import KitCommentPopup from "./Popups/CommentPopup/KitCommentPopup";
import AddComponentButton from "./ComponentButton/AddComponentButton";
import ComponentsPopup from "./Popups/ComponentsPopup/ComponentsPopup";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import Preloader from "../../Common/Preloader/Preloader";
import GlobalCalculator from "../../Common/GlobalCalculator/GlobalCalculator";

const Calculator = () => {
    const {orderId, id} = useParams();
    const navigate = useNavigate();
    const [showKitCommentPopup, setShowKitCommentPopup] = useState(false);
    const [showComponentPopup, setShowComponentPopup] = useState(false);
    const [showFabricAmountPopup, setShowFabricAmountPopup] = useState(false);
    const [template, setTemplate] = useState(null);
    const [activeSecondSize, setActiveSecondSize] = useState(null);
    const [size, setSize] = useState(null);
    const [customTemplateSize, setCustomTemplateSize] = useState(null);
    const [activeSize, setActiveSize] = useState(() =>
        !!id ? {index: null, standard: 1} : {index: null, standard: 0}
    );
    const [components, setComponents] = useState(null);
    const [fabric, setFabric] = useState(null);
    const dispatch = useAppDispatch();
    const [product, setProduct] = useState(null);
    const [form, setForm] = useState(null);
    const [kitCommentText, setKitCommentText] = useState("");
    const [customComponents, setCustomComponents] = useState([]);
    const [selectedOptionIds, setSelectedOptionIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fabricConsumption, setFabricConsumption] = useState(0);
    const [componentSizes, setComponentSizes] = useState([]);
    const [isKitSplitted, setIsKitSplitted] = useState(false);

    useEffect(() => {
        if (fabric && size) {
            const accessToken = getAccessToken();
            setIsLoading(true);
            getFabricTemplate(accessToken, fabric.id, size.id).then((resp) => {
                if (resp && resp.kit) {
                    setProduct(resp)
                } else {
                    setForm(null);
                    setProduct(null);
                    setComponents(null);
                    setCustomComponents([]);
                    setKitCommentText("");
                    setSelectedOptionIds([])
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }

    }, [fabric, size])

    const calcSave = () => {
        const isEdit = !!id;
        if (!isEdit) {
            const updatedForm = {
                ...form,
                comment: kitCommentText,
                option: [...selectedOptionIds],
                custom_size: customTemplateSize,
                additional_fabric_consumption_count: fabricConsumption,
                is_split: isKitSplitted
            };
            setForm(updatedForm);
            const accessToken = getAccessToken();
            setKit(accessToken, updatedForm).then(resp => {
                if (resp?.order_product_id) {
                    navigate(`/products/${orderId}`);
                } else {
                    console.log(resp);
                }
            }).catch(err => console.error(err));
        } else {
            const mainComponents = form.additional_components.filter(item => item.is_main);
            const additionalComponents = form.additional_components.filter(item => !item.is_main);
            const formattedEditForm = {
                component: [ ...form.template_components, ...mainComponents].map(item => ({
                    id: item.component_template_id,
                    ...(item?.original_size_id && { original_size_id: item.original_size_id }),
                    option: item.option,
                    ...(item?.custom_size !== undefined && { custom_size: item.custom_size }),
                    ...(item?.comment !== undefined && { comment: item.comment }),
                    ...(item?.mono_fabric_type !== undefined && { mono_fabric_type: item.mono_fabric_type })
                })),
                ...(form?.new_fabric && { new_fabric: form.new_fabric }),
                additional_components: additionalComponents,
                is_split: isKitSplitted,
                custom_size: customTemplateSize,
                comment: kitCommentText,
                option: [...selectedOptionIds],
                additional_fabric_consumption_count: fabricConsumption,
            };
            const accessToken = getAccessToken();
            setUpdateKit(accessToken, formattedEditForm, product?.kit[0].id).then((response) => {
                if (response?.order_product_id) {
                    navigate(`/products/${orderId}`);
                } else {
                    console.log(response);
                }
            })
        }
    }

    const openKitCommentPopup = () => {
        setShowKitCommentPopup(true);
        dispatch(setIsActivePopup(true));
    }

    const onSaveKitComment = () => {
        setShowKitCommentPopup(false);
        dispatch(setIsActivePopup(false));
    }

    const openComponentPopup = () => {
        setShowComponentPopup(true);
        dispatch(setIsActivePopup(true));
    }

    const openFabricConsumptionPopup = () => {
        setShowFabricAmountPopup(true);
        dispatch(setIsActivePopup(true));
    }

    const handleFabricConsumptionDelete = () => {
        let newValue = Math.floor(fabricConsumption / 10);
        setFabricConsumption(newValue);
    }

    const handleFabricConsumptionChange = (e) => {
        const value = e.target.textContent;
        const fabricString = String(fabricConsumption);
        const newFabricValue = Number(fabricString + value);
        if (newFabricValue <= 100000) {
            setFabricConsumption(newFabricValue);
        }
    }

    const addCustomComponent = (newComponent) => {
        setCustomComponents(prevComponents => [...prevComponents, { ...newComponent, is_main: false }]);
        setForm(prevForm => ({
            ...prevForm,
            additional_components: [
                ...prevForm.additional_components,
                {
                    kit_template_id: 0,
                    component_template_id: newComponent.id,
                    option: [],
                }
            ]
        }));
        setShowComponentPopup(false);
        dispatch(setIsActivePopup(false));
    }

    const splitKit = () => {
        const accessToken = getAccessToken();
        setIsLoading(true);
        const payload = id ? { kit_id: components.id } : { kit_template_id: components.id };
        setSplitKit(accessToken, payload).then(resp => {
            if (resp?.length > 0) {
                const additionalSizes = resp.map(components => ({
                    id: components.id,
                    sizes: components.components.map(component => ({
                        id: component.id,
                        name: component.name,
                        price_retail: component.price_retail,
                        size: component.size,
                        image: component.image[0]
                    }))
                }));
                const newComponentSizes = [];
                for (let i = 0; i < componentSizes.length; i++) {
                    let index;
                    for (let j = 0; j < additionalSizes.length; j++) {
                        if (additionalSizes[j].id === componentSizes[i].id) {
                            newComponentSizes.push({
                                ...componentSizes[i],
                                sizes: [...componentSizes[i].sizes, ...additionalSizes[j].sizes]
                            })
                            index = j;
                            break;
                        }
                    }
                    additionalSizes.splice(index, 1);
                    if (additionalSizes.length === 0) {
                        break;
                    }
                }
                if (newComponentSizes.length > 0) {
                    setComponentSizes(newComponentSizes);
                }
                setIsKitSplitted(true);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const handleSelectSize = (componentIndex, sizeIndex) => {
        const newComponentSizes = componentSizes.map((component, idx) => ({
            ...component,
            selected: componentIndex === idx ? sizeIndex : component.selected
        }));
        setComponentSizes(newComponentSizes);
        setForm(prevForm => ({
            ...prevForm,
            template_components: prevForm.template_components.map((component, idx) => ({
                ...component,
                component_template_id: idx === componentIndex ? newComponentSizes[idx].sizes[sizeIndex].id : component.component_template_id,
                ...(id && (idx === componentIndex) && { original_size_id: newComponentSizes[idx].id })
            }))
        }))
    }

    const resetKitValues = () => {
        setComponents(prevComponents => ({ ...prevComponents, components: [] }));
        setForm(prevForm => ({ ...prevForm, template_components: [] }));
        setComponentSizes([]);
        setCustomComponents([]);
        setActiveSecondSize(null);
        setIsKitSplitted(false);
    }

    const handleFabricTypeChange = (newFabric) => {
        if (!!id) {
            if (!fabric) {
                setFabric(newFabric);
            } else {
                setFabric(prevFabric => ({ ...prevFabric, mono_fabric_type: newFabric.mono_fabric_type }));
                const newTemplateComponents = form.template_components.map(({ mono_fabric_type, ...rest }) => rest);
                setForm(prevForm => ({ ...prevForm, template_components: newTemplateComponents, new_fabric: newFabric.id }));
                if (newFabric.mono_fabric_type.length !== 2) {
                    const kitComponents = components.components.map(component => ({
                        ...component,
                        mono_fabric_type: newFabric.mono_fabric_type.join(''),
                    }));
                    setComponents(prevComponents => ({ ...prevComponents, components: kitComponents }));
                } else {
                    const kitComponents = [];
                    const accessToken = getAccessToken();
                    getMonoFabricTypeTemplates(accessToken).then(resp => {
                        if (resp?.length > 0) {
                            for (const component of components.components) {
                                let type = resp.find(el => el.id === component.type_id);
                                kitComponents.push({ ...component, mono_fabric_type: type.mono_fabric_type });
                            }
                            setComponents(prevComponents => ({ ...prevComponents, components: kitComponents }));
                        }
                    })
                }
            }
        } else {
            setFabric(newFabric);
            if (fabric?.type?.id === newFabric?.type?.id) {
                const newTemplateComponents = form.template_components.map(({ mono_fabric_type, ...rest }) => rest);
                setForm(prevForm => ({ ...prevForm, template_components: newTemplateComponents, fabric_id: newFabric.id }));
                if (newFabric.mono_fabric_type.length !== 2) {
                    const kitComponents = components.components.map(item => ({ ...item, mono_fabric_type: newFabric.mono_fabric_type.join('') }));
                    setComponents({ ...components, components: kitComponents });
                } else {
                    const kitComponents = [];
                    const accessToken = getAccessToken();
                    getMonoFabricTypeTemplates(accessToken).then(resp => {
                        if (resp?.length > 0) {
                            for (const component of components.components) {
                                let type = resp.find(el => el.id === component.type_id);
                                kitComponents.push({ ...component, mono_fabric_type: type.mono_fabric_type });
                            }
                            setComponents(prevComponents => ({ ...prevComponents, components: kitComponents }));
                        }
                    })
                }
            } else {
                const initialActiveSize = !!id ? {index: null, standard: 1} : {index: null, standard: 0};
                setActiveSize(initialActiveSize);
                resetKitValues();
            }
        }
    }

    return (
        <div className={styles.calculator}>
            <h2>Тип тканини</h2>
            <FabricType setTemplate={setTemplate} fabric={fabric} setComponents={setComponents} id={id}
                        setProduct={setProduct} setKitCommentText={setKitCommentText} setForm={setForm}
                        isEdit={!!id} isLoading={isLoading} setIsLoading={setIsLoading} setFabricConsumption={setFabricConsumption}
                        setComponentSizes={setComponentSizes} setIsKitSplitted={setIsKitSplitted}
                        onFabricTypeChange={handleFabricTypeChange} setCustomComponents={setCustomComponents}/>
            <h2>Розмір комплекту</h2>
            <FabricSize activeSize={activeSize} setActiveSize={setActiveSize} kitTemplateSizes={template?.kit_template_size}
                        setKitSize={setSize} setCustomTemplateSize={setCustomTemplateSize} setKitCommentText={setKitCommentText}
                        isEdit={!!id} onSizeChange={resetKitValues}/>
            {product && product.kit && (
                <FabricSizeSecond
                    activeSecondSize={activeSecondSize}
                    setActiveSecondSize={(idx) => {
                        setActiveSecondSize(idx);
                        setIsKitSplitted(false);
                        setCustomComponents([]);
                    }}
                    orderId={orderId}
                    setComponents={setComponents}
                    setForm={setForm}
                    product={product}
                    monoFabricType={fabric?.mono_fabric_type || []}
                    isEdit={!!id}
                    setFabricConsumption={setFabricConsumption}
                    setComponentSizes={setComponentSizes}
                />
            )}
            {components && (
                <div className={styles.kitWrapper}>
                    <h2 className={styles.setTitle}>Комплект</h2>
                    {!isKitSplitted && components?.components?.length > 0 && (
                        <div className={styles.templateKitWrapper}>
                            <div className={styles.kitSplitButton}>
                                <button onClick={splitKit}>Розформувати комплект</button>
                            </div>
                            <FabricSet components={components.components} setComponents={setComponents}
                                    kitId={components.id} types={fabric?.mono_fabric_type} form={form} setForm={setForm}
                                    isEdit={!!id} componentSizes={componentSizes} onSelectSize={handleSelectSize}
                                    isKitSplitted={isKitSplitted}
                                    defaultFabricImage={fabric?.image || fabric?.type.image}
                            />
                        </div>
                    )}
                    {isKitSplitted && components?.components?.length > 0 && (
                        <FabricSet components={components.components} setComponents={setComponents}
                                kitId={components.id} types={fabric?.mono_fabric_type} form={form} setForm={setForm}
                                isEdit={!!id} componentSizes={componentSizes} onSelectSize={handleSelectSize}
                                isKitSplitted={isKitSplitted} setComponentSizes={setComponentSizes}
                                defaultFabricImage={fabric?.image || fabric?.type.image}
                        />
                    )}
                    {customComponents?.length > 0 && (
                        <FabricSet customComponents={customComponents} setCustomComponents={setCustomComponents}
                            kitId={components.id} types={fabric?.mono_fabric_type} form={form} setForm={setForm}
                            isEdit={!!id} componentSizes={componentSizes} onSelectSize={handleSelectSize}
                            isKitSplitted={isKitSplitted}
                            defaultFabricImage={fabric?.image || fabric?.type.image}
                        />
                    )}
                </div>
            )}
            {product?.kit_options && (
                <>
                    <div className={styles.bottomKitButtons}>
                        <AddCommentButton onClick={openKitCommentPopup}/>
                        <AddComponentButton onClick={openComponentPopup}/>
                    </div>
                    <h2>Опції</h2>
                    <FabricOptions
                        options={product.kit_options}
                        selectedOptionIds={selectedOptionIds}
                        setSelectedOptionIds={setSelectedOptionIds}
                        openFabricConsumptionPopup={openFabricConsumptionPopup}
                        fabricConsumption={fabricConsumption}
                    />
                </>
            )}
            <CalcButton
                action={calcSave}
                isDisabled={!components || (components?.components?.length === 0 && customComponents?.length === 0)}
                buttonName="Обрахувати і Зберегти"
            />
            {components?.price_retail && !!id && (
                <FabricPrice price={components.price_retail}/>
            )}
            {showKitCommentPopup && (
                <GlobalPopup topPopup title={'Коментар'} onClose={() => setShowKitCommentPopup(false)}>
                    <KitCommentPopup kitCommentText={kitCommentText} setKitCommentText={setKitCommentText}
                                     onSaveKitComment={onSaveKitComment}/>
                </GlobalPopup>
            )}
            {showComponentPopup && (
                <GlobalPopup topPopup title={'Комплектуючі:'} onClose={() => setShowComponentPopup(false)}>
                    <ComponentsPopup
                        addCustomComponent={addCustomComponent}
                        defaultFabricImage={fabric?.image || fabric?.type.image}
                        fabricId={fabric?.id}
                    />
                </GlobalPopup>
            )}
            {showFabricAmountPopup && (
                <GlobalPopup title={'Витрата тканини:'} onClose={() => setShowFabricAmountPopup(false)}>
                    <GlobalCalculator handleChange={handleFabricConsumptionChange} handleDelete={handleFabricConsumptionDelete} onSubmit={() => setShowFabricAmountPopup(false)}>
                        <div className={styles.fabricInputWrap}>
                            <input id='fabric_amount' name="fabric_amount" value={fabricConsumption}
                                    className={'baseInput'} type="text" readOnly/><span>&nbsp;см</span>
                        </div>
                    </GlobalCalculator>
                </GlobalPopup>
            )}
            {isLoading && <Preloader />}
        </div>
    )
}

export default Calculator;
import React from "react";
import styles from './discountField.module.scss';
import {setIsActivePopup} from "../../../store/main-slice";
import {useAppDispatch} from "../../../hooks/redux";
import GlobalPopup from "../GlobalPopup/GlobalPopup";
import GlobalCalculator from "../GlobalCalculator/GlobalCalculator";

const DiscountField = ({
    percentInputValue,
    setPercentInputValue,
    UAHInputValue,
    setUAHInputValue,
    discountType,
    setDiscountType,
    onSubmit,
    popup,
    setPopup,
    isDisabled
}) => {
    const dispatch = useAppDispatch();

    const onSubmitPopup = () => {
        setPopup(null);
        onSubmit();
    }

    const onChangeDiscount = (value) => {
        if (value !== discountType) {
            setDiscountType(value);
        };
        setPopup(value);
        setTimeout(() => {
            dispatch(setIsActivePopup(true))
        }, 30)
    }

    const handlePercentChange = (e) => {
        let value = e.target.textContent;
        let potentialNewValue = Number(String(percentInputValue) + value);
        if (potentialNewValue <= 100) {
            setPercentInputValue(potentialNewValue);
        }
    }

    const handlePercentDelete = () => {
        let newValue = Math.floor(percentInputValue / 10);
        setPercentInputValue(newValue);
    }

    const handleUAHChange = (e) => {
        let value = e.target.textContent;
        const newUAHValue = Number(String(UAHInputValue) + value);
        if (newUAHValue <= 100000) {
            setUAHInputValue(newUAHValue);
        }
    }

    const handleUAHDelete = () => {
        let newValue = Math.floor(UAHInputValue / 10);
        setUAHInputValue(newValue);
    }

    return (
        <div className={styles.discountField}>
            <span>Знижка:</span>
            <div className={`${styles.discountField__wrapper} ${isDisabled ? styles.disabled : ''}`}>
                <label className={styles.field}>
                    <input type="radio" checked={discountType === 'PERC' && percentInputValue !== 0}
                           onClick={() => onChangeDiscount('PERC')} onChange={() => onChangeDiscount('PERC')}
                           disabled={isDisabled}/>
                    <span>{percentInputValue} %</span>
                </label>
                <label className={styles.field}>
                    <input type="radio" checked={discountType === 'VAL' && UAHInputValue !== 0}
                           onClick={() => onChangeDiscount('VAL')} onChange={() => onChangeDiscount('VAL')}
                           disabled={isDisabled}/>
                    <span>{UAHInputValue} грн</span>
                </label>
            </div>
            {popup === 'PERC' && (
                <GlobalPopup title={'Знижка'} onClose={() => setPopup(null)}>
                    <GlobalCalculator handleChange={handlePercentChange} handleDelete={handlePercentDelete} onSubmit={() => onSubmitPopup()}>
                        <div className={styles.percentInputWrap}>
                            <input id='percent_discount' name="percent_discount" value={percentInputValue}
                                   className={'baseInput'} type="text" readOnly/><span>%</span>
                        </div>
                    </GlobalCalculator>
                </GlobalPopup>
            )}
            {popup === 'VAL' && (
                <GlobalPopup title={'Знижка'} onClose={() => setPopup(null)}>
                    <GlobalCalculator handleChange={handleUAHChange} handleDelete={handleUAHDelete} onSubmit={() => onSubmitPopup()}>
                        <div className={styles.UAHInputWrap}>
                            <input id='uah_discount' name="uah_discount" value={UAHInputValue}
                                   className={'baseInput'} type="text" readOnly/><span>&nbsp;грн</span>
                        </div>
                    </GlobalCalculator>
                </GlobalPopup>
            )}
        </div>
    )
}

export default DiscountField;
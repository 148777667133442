import styles from './bill.module.scss';

export const PayMethod = ({ payMethod, auditDates, isDisabled, isFullDisabled }) => {
    const onChangePay = (value) => {
        auditDates('method', value);
    }

    return (
        <div className={`${styles.payChanger} ${isDisabled ? styles.disabled : ''}`}>
            <span>Метод оплати</span>
            <div className={styles.items}>
                <label className={`${styles.item} ${isFullDisabled && !isDisabled ? styles.disabled : ''}`}>
                    <input type="radio" checked={payMethod === 'FULL'}
                           onChange={() => onChangePay('FULL')} disabled={isDisabled || isFullDisabled} />
                    <span>Вся сума</span>
                </label>
                <label className={styles.item}>
                    <input type="radio" checked={payMethod === 'PREPAYMENT'}
                           onChange={() => onChangePay('PREPAYMENT')} disabled={isDisabled} />
                    <span>Передоплата</span>
                </label>
                <label className={styles.item}>
                    <input type="radio" checked={payMethod === 'POSTPAID'}
                           onChange={() => onChangePay('POSTPAID')} disabled={isDisabled} />
                    <span>Післяоплата</span>
                </label>
            </div>
        </div>
    )
}

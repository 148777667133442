import styles from "./payment-method.module.scss";

export const PaymentMethod = ({ paymentMethod, onChange, isDisabled }) => {
    return (
        <div className={`${styles.payChanger} ${isDisabled ? styles.disabled : ''}`}>
           {/* <span>Спосіб оплати доставки</span>*/}
            <div className={styles.items}>
                <label className={styles.item}>
                    <input type="radio" checked={paymentMethod === 'CASH'}
                        onChange={() => onChange('CASH')} disabled={isDisabled} />
                    <span>Готівка</span>
                </label>
                <label className={styles.item}>
                    <input type="radio" checked={paymentMethod === 'NON_CASH'}
                        onChange={() => onChange('NON_CASH')} disabled={isDisabled} />
                    <span>Картка</span>
                </label>
            </div>
        </div>
    )
}

import React, {useEffect, useState} from "react";
import styles from './Products.module.scss';
import ProductActions from "./ProductActions/ProductActions";
import DiscountField from "../../Common/DiscountField/DiscountField";
import CartProducts from "./CartProducts/CartProducts";
import TotalPrice from "../../Common/TotalPrice/TotalPrice";
import Preloader from "../../Common/Preloader/Preloader";
import {setGeneralOrderId, setIsNotFound} from "../../../store/main-slice";
import { useParams } from "react-router-dom";
import NotFound from "../../Common/NotFound/NotFound";
import { useAppDispatch } from "../../../hooks/redux";
import { getAccessToken } from "../../../utils/authTokens";
import { getOrderInfo, getSuborders, removeOrder } from "../../../api/ordersApi";
import { getCartOrder } from "../../../api/productsApi";
import { updatePayment } from "../../../api/paymentApi";
import { getPriceWithDiscount } from "../../../utils/getPriceWithDiscount";
import { Suborder } from "../Main/Order/Suborder";

const Products = () => {
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const [suborders, setSuborders] = useState([]);
    const [warehouseItems, setWarehouseItems] = useState([]);
    const [discountType, setDiscountType] = useState('');
    const [percentInputValue, setPercentInputValue] = useState(0);
    const [UAHInputValue, setUAHInputValue] = useState(0);
    const [popup, setPopup] = useState(null);
    const isDisabled = order?.payment?.status === 'PAID' || order?.payment?.status === 'PAY_WAIT';

    const sendPayment = (payment) => {
        const accessToken = getAccessToken();
        updatePayment(accessToken, order.id, payment).then(res => {
            if (res?.id) {
                setOrder(prevOrder => ({ ...prevOrder, payment: res }));
            }
        })
    }

    const handleSaveFinalPrice = () => {
        const discountValue = discountType === 'PERC' ? percentInputValue : UAHInputValue;
        const priceWithDiscount = getPriceWithDiscount(order?.payment?.amount, discountType, discountValue);
        const payment = {
            final_amount: priceWithDiscount,
            discount_type: discountType,
            discount_amount: discountValue,
        }
        sendPayment(payment);
    }

    useEffect(() => {
        let accessToken = getAccessToken();
        getOrderInfo(accessToken, id).then((response) => {
            if (response?.id) {
                if (response.general_order) {
                    dispatch(setGeneralOrderId(response.general_order.id));
                } else {
                    dispatch(setGeneralOrderId(null));
                }
                const newOrder = response;
                if (!response?.payment) {
                    updatePayment(accessToken, response.id, {'post_paid_amount': null}).then((res) => {
                        if (res.id) {
                            newOrder.payment = res;
                        }
                    });
                }
                getSuborders(accessToken, { general_order: response.id }).then(result => {
                    if (result?.length > 0) {
                        setSuborders(result);
                    }
                }).catch(err => console.error(err));
                setOrder(newOrder);
                if (newOrder.payment) {
                    const { discount_type, discount_amount } = newOrder.payment;
                    setDiscountType(discount_type);
                    if (discount_type === 'PERC') {
                        setPercentInputValue(discount_amount || 0);
                    } else {
                        setUAHInputValue(discount_amount || 0);
                    }
                    accessToken = getAccessToken();
                    getCartOrder(accessToken, id).then((items) => {
                        if (items?.length > 0) {
                            setWarehouseItems(items);
                        }
                    }).finally(() => {
                        setIsLoading(false);
                    })
                }
            } else {
                setIsLoading(false);
                dispatch(setIsNotFound(true));
            }
        }).catch(err => {
            console.error(err);
            setIsLoading(false);
        }).finally(() => setIsLoading(false))
    }, [])

    useEffect(() => {
        if (order?.id && warehouseItems.length > 0) {
            const result = warehouseItems.reduce((sum, {warehouse_item, kit}) => sum + (warehouse_item ? warehouse_item.price : kit.price_retail), 0);
            const { discount_type, discount_amount } = order.payment;
            const priceWithDiscount = getPriceWithDiscount(result, discount_type, discount_amount);
            if (result !== order.payment.amount || priceWithDiscount !== order.payment.final_amount) {
                sendPayment({ amount: result, final_amount: priceWithDiscount });
            }
        }
    }, [order?.id, warehouseItems]);

    const handleOrderPayment = (payment) => {
        setOrder(prevOrder => ({ ...prevOrder, payment: payment }));
    }

    const deleteSuborder = (id) => {
        const accessToken = getAccessToken();
        removeOrder(accessToken, id).then(status => {
            if (status === 204) {
                const newSuborders = suborders.filter(item => item.id !== id);
                setSuborders(newSuborders);
            }
        });
    }

    if (isLoading) {
        return (
            <Preloader />
        )
    } else if (!order) {
        return (
            <NotFound message={`Замовлення №${id} не знайдено`} />
        )
    }

    return (
        <div className={styles.products} style={{ backgroundColor: order?.is_general ? '#D9D1E0' : '#f7eadf' }}>
            <h2 className={styles.products__title}>Товар</h2>
            {warehouseItems && (
                <CartProducts
                    items={warehouseItems} 
                    setItems={setWarehouseItems} 
                    setOrderPayment={handleOrderPayment}
                    isDisabled={isDisabled}
                />
            )}
            <ProductActions
                order={order}
                popup={popup}
                setPopup={setPopup}
                setOrder={setWarehouseItems}
                isDisabled={isDisabled}
            />
            <TotalPrice totalPrice={order?.payment?.amount} priceWithDiscount={order?.payment?.final_amount} />
            <DiscountField
                percentInputValue={percentInputValue}
                setPercentInputValue={setPercentInputValue}
                UAHInputValue={UAHInputValue}
                setUAHInputValue={setUAHInputValue}
                discountType={discountType}
                setDiscountType={setDiscountType}
                onSubmit={handleSaveFinalPrice}
                popup={popup}
                setPopup={setPopup}
                orderId={order?.id}
                isDisabled={isDisabled}
            />
            {suborders.length > 0 && (
                <div className={styles.suborderWrapper}>
                    <h3>Підзамовлення</h3>
                    {suborders.map(item => (
                        <Suborder key={item.id} item={item} onDelete={deleteSuborder}/>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Products;